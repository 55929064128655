  .USDUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ALGOUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ADAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#0000ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .DOGEUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XDGUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XTZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ETHUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1c4587;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XETHZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1c4587;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .GNOUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XBTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .BTCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .COVALUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#aaa;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XXBTZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .KAVAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#dd7e6b;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .MANAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#a64d79;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .WAVESUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ETCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XETCZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .AAVEUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#a64d79;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .FILUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .SCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .SOLUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#FFFFFF;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .OMGUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ENJUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#8e7cc3;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .CRVUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .UNIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .DASHUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#a4c2f4;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .MATICUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .GRTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .TRACUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .LTCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3d85c6;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .REPV2USD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#351c75;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XLMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9fc5e8;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .OCEANUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d5a6bd;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .REPUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XREPZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .BALUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#93c47d;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .OXTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#674ea7;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ATOMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .MLNUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XMLNZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
  .KSMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ICXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .BATUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XMRUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ea4335;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XXMRZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ea4335;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ANTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .COMPUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .KNCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .QTUMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .LSKUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .NANOUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .SNXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#0000ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .STORJUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .DOTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .LINKUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ZECUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XZECZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .EOSUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .EXPLORE{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#00070f;font-family:'Arial';    font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}

 .USTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e06666;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.OGNUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BANDUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PAXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.NUUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ALCXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#f1c232;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RLCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff9900;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.undefined{border-right: none;border-bottom:1px SOLID #000000; display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DASHUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POWRUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ZENUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ARPAUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BTRSTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POWRUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CLVUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CVCUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CROUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.UMAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#dddddd;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ARPAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FETUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LRCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MDTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DESOUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RBNUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#dddddd;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PAXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.YFIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.1INCHUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.API3USD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POLSUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ALCXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AVAXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ZRXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ICPUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ENSUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9999ff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GRTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRUUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#4285f4;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AVAXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ea4335;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CVCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.REQUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PLAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46cfb1;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RLYUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#f6b26b;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IOTXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#34a853;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RAIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#34a853;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUPERUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#34a853;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.undefined{border-right: none;border-bottom:1px SOLID #000000; display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fbbc04;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AXSUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MKRUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FARMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUSHIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e989d0;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RADUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GYENUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#0b5394;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LCXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1c4587;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WLUNAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffe599;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CGLDUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffe599;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MASKUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MUSDUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DNTUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#efefef;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.USTUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#efefef;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RENUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#efefef;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AMPUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RGTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CHZUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LCXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUKUUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POLYUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRUUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DAIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRACUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LOOMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#79d8ff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LOOMUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#79d8ff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AXSUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#4a86e8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.QNTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.COTIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3dc2e0;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CHZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e06666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BONDUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff0000;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.JASMYUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BADGERUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BADGERUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FOXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IDEXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.YFIIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BLZUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.KRLUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#8e7cc3;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ORNUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3d85c6;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.QUICKUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ACHUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CROUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DNTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.NKNUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.KEEPUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ANKRUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9fc5e8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LPTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6aa84f;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DAIUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#f1c232;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GTCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRBUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUKUUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.NMRUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BICOUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IMXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.XYOUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ZENUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GALAUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SKLUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FETUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.VGXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#674ea7;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PERPUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#666666;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WCFGUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BNTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WBTCUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.XLMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CTSIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AGLDUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SHIBUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRIBEUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ASMUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POLYUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}

.FXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AUCTIONUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MIRUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ASMUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BTRSTUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RADUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RADUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ENSUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CLVUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.XYOUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PERPUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AGLDUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ORNUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.KRLUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WLUNAUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DDXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FARMUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IOTXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ICPUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GALAUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.USDTUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.REQUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ETHUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.USDTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RARIUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WCFGUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.VGXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FORTHUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.DDXUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.MCO2USD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.GODSUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.SPELLUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}

  .USDEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ALGOEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ADAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#0000ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .DOGEEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XDGEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XTZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ETHEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1c4587;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XETHZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1c4587;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .GNOEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XBTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .BTCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .COVALEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#aaa;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XXBTZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .KAVAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#dd7e6b;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .MANAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#a64d79;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .WAVESEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ETCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XETCZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .AAVEEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#a64d79;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .FILEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .SCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .SOLEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#FFFFFF;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .OMGEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ENJEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#8e7cc3;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .CRVEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .UNIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .DASHEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#a4c2f4;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .MATICEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .GRTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .TRACEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .LTCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3d85c6;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .REPV2EUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#351c75;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XLMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9fc5e8;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .OCEANEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d5a6bd;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .REPEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XREPZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .BALEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#93c47d;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .OXTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#674ea7;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ATOMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .MLNEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XMLNZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
  .KSMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ICXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .BATEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XMREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ea4335;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XXMRZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ea4335;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ANTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .COMPEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .KNCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .QTUMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .LSKEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .NANOEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .SNXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#0000ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .STORJEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .DOTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#fff;font-family:'Arial';font-size:8pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .LINKEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .ZECEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .XZECZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffd966;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .EOSEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
 .EXPLORE{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#00070f;font-family:'Arial';    font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}

 .USTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e06666;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.OGNEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BANDEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PAXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.NUEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ALCXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#f1c232;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RLCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff9900;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.undefined{border-right: none;border-bottom:1px SOLID #000000; display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DASHEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POWREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ZENEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ARPAUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BTRSTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POWRUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CLVEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CVCUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CROEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.UMAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ARPAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FETUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LRCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MDTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DESOEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RBNEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PAXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.YFIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.1INCHEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.API3EUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POLSEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ALCXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AVAXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ZRXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ICPEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#d9d9d9;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ENSEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#c9daf8;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GRTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRUEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#4285f4;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AVAXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ea4335;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CVCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.REQEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PLAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46cfb1;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RLYEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#f6b26b;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IOTXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#34a853;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RAIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#34a853;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUPEREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#34a853;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.undefined{border-right: none;border-bottom:1px SOLID #000000; display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fbbc04;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AXSUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MKREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FARMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#46bdc6;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUSHIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e989d0;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RADEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#00ff00;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GYENEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#0b5394;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LCXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1c4587;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WLUNAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffe599;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CGLDEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffe599;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MASKEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MUSDEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DNTUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#efefef;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.USTUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#efefef;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RENEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#efefef;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AMPEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff00ff;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RGTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CHZUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LCXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUKUUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POLYEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRUUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DAIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff2cc;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRACUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9900ff;text-align:center;font-weight:bold;color:#f3f3f3;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LOOMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#79d8ff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LOOMUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#79d8ff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AXSEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#4a86e8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.QNTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff6d01;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.COTIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3dc2e0;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CHZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e06666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BONDEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ff0000;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.JASMYEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BADGEREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BADGERUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FOXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IDEXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.YFIIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BLZEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#e69138;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.KRLEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#8e7cc3;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ORNEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3d85c6;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.QUICKEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6d9eeb;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ACHEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CROUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DNTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.NKNEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#073763;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.KEEPEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ANKREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#9fc5e8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.LPTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#6aa84f;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DAIUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#f1c232;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GTCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRBEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SUKUEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.NMREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BICOEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IMXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.XYOEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#999999;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ZENUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GALAEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SKLEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FETEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#fff;text-align:center;font-weight:bold;color:#000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.VGXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#674ea7;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PERPEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#b6d7a8;text-align:center;font-weight:bold;color:#666666;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WCFGEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BNTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WBTCEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.XLMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CTSIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AGLDEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.SHIBEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#434343;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.TRIBEEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ASMEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.POLYUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#3c78d8;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}

.FXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AUCTIONEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.MIREUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ASMUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BTRSTUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.BTRSTUSD{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RADUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RADUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ENSUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.CLVUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.XYOUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.PERPUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.AGLDUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ORNUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.KRLUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WLUNAUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.DDXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FARMUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.IOTXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ICPUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.GALAUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.USDTUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.REQUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.ETHUSDC{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.USDTEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.RARIEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.WCFGUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.VGXUSDT{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#ffffff;text-align:center;font-weight:bold;color:#000000;font-family:'Arial';font-size:9pt;vertical-align:bottom;white-space:nowrap;direction:ltr;}
.FORTHEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.DDXEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.MCO2EUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.GODSEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#666666;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}
.SPELLEUR{ display: inline-block;width:75px;height:20px; border-radius: 4px;background-color:#1155cc;text-align:center;font-weight:bold;color:#ffffff;font-family:'Arial';font-size:9pt;vertical-align:top;white-space:nowrap;direction:ltr; cursor: pointer;}


#card {
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 10px 20px rgb(40 51 75 / 50%);
  box-sizing: border-box;
  /* background-color: #888; */
  /* Add your logo as a background image */
  /* background-image: url("https://lithic.com/sterling/img/logo-black.4793a8fe.svg"); */
  background-repeat: no-repeat;
  /* background-position will need adjusting depending on your logo */
  background-position: bottom 150px left 190px;
  color: #f7f7f7;
  display: flex;
  flex-direction: column;
  font-family: "Roboto Mono", sans-serif;
  font-size: 16px;
  height: 10.61rem;
  justify-content: space-between;
  line-height: 24px;
  margin: 40px auto;
  overflow: hidden;
  padding: 24px;
  user-select: none;
  width: 17rem;
  background: url('../assets/images/visa1.svg') no-repeat;
  background-size: cover;
}

.pan-separator {
  margin: 6px;
}

#name {
  border-radius: 6px;
  top: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Trykker';
  height: 32px;
  justify-content: center;
  left: 14px;
  line-height: 30px;
  padding: 2px 10px 0;
  position: absolute;
}

#lifetime {
  border-radius: 6px;
  top: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Trykker';
  height: 32px;
  justify-content: center;
  left: 174px;
  line-height: 30px;
  padding: 2px 10px 0;
  position: absolute;
}

#current {
  border-radius: 6px;
  top: 45px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Trykker';
  height: 32px;
  justify-content: center;
  left: 14px;
  line-height: 30px;
  padding: 2px 10px 0;
  position: absolute;
}

#pan {
  border-radius: 6px;
  bottom: 45px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  left: 14px;
  letter-spacing: 6px;
  line-height: 30px;
  padding: 2px 10px 0;
  position: absolute;
}

#expiry {
  border-radius: 6px;
  bottom: 15px;
  font-size: 16;
  font-weight: 400;
  left: 20px;
  line-height: 30px;
  opacity: 0.8;
  padding: 2px 4px 0;
  position: absolute;
}

#month,
#year {
  border-radius: 6px;
  cursor: pointer;
  line-height: 30px;
  padding: 4px 1px;
}

#cvv {
  border-radius: 6px;
  bottom: 15px;
  cursor: pointer;
  font-size: 16;
  font-weight: 400;
  left: 92px;
  line-height: 30px;
  margin-left: 25px;
  opacity: 0.8;
  padding: 2px 4px 0;
  position: absolute;
}

#expiry::before {
  content: 'EXP ';
}

#cvv::before {
  content: 'CVV ';
}

#cvv:hover,
#pan:hover,
#month:hover,
#year:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#cvv:active,
#pan:active,
#month:active,
#year:active {
  background-color: rgba(0, 0, 0, 0.05);
}

#alert {
  display: none;
}

.rotated0 {
  transform: rotate(0deg);
  /* Equal to rotateZ(45deg) */
}

.rotated1 {
  transform: rotate(30deg);
  /* Equal to rotateZ(45deg) */
}

.rotated2 {
  transform: rotate(60deg);
  /* Equal to rotateZ(45deg) */
}

.rotated3 {
  transform: rotate(90deg);
  /* Equal to rotateZ(45deg) */
}


.visa {
  background: url('../assets/images/visa1.svg') no-repeat;
  background-size: cover;
  background-size: 63%;
}